// In a separate file like Auth.js
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import { database } from "./firebase"; // Adjust path to your Firebase config

const auth = getAuth();

export const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  return currentUser;
};

// Example login function
export const login = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};
