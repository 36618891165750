// QRScanner.js
import React, { useEffect, useState } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import { ref, get } from "firebase/database";
import { database } from "./firebase.js"; // Adjust path to your firebase config file
import { query, orderByChild, equalTo, update } from "firebase/database";

const QRScanner = () => {
  const [isScanning, setIsScanning] = useState(false); // Track if a scan is in progress

  useEffect(() => {
    const onScanSuccess = (decodedText, decodedResult) => {
      if (isScanning) return; // If a scan is already in progress, ignore further scans
      setIsScanning(true); // Set scanning flag to prevent multiple scanss

      // Display QR Code data
      document.getElementById('qr-reader-results').innerText = `QR Code Data: ${decodedText}`;

      // Extract the TC value from the QR code
      const data = decodedText.split(', ');
      const tcValue = data[0].split(': ')[1];
      console.log(`TC Value: ${tcValue}`);

      // Handle the scanned QR code (checking in Firebase)
      checkQRCode(tcValue);

      // Reset the scanning flag after 3 seconds (adjust as needed)
      setTimeout(() => {
        setIsScanning(false);
      }, 3000); // 3-second cooldown to prevent multiple scans
    };

    const onScanFailure = (error) => {
      // Handle scan failure
      console.warn(`Code scan error: ${error}`);
    };

    const html5QrcodeScanner = new Html5QrcodeScanner(
      "qr-reader", { fps: 10, qrbox: 250 });
    html5QrcodeScanner.render(onScanSuccess, onScanFailure);

    return () => {
      html5QrcodeScanner.clear();
    };
  }, [isScanning]); // Include `isScanning` as a dependency to re-render after cooldown

  const checkQRCode = async (tcValue) => {

    const userRef = ref(database, `qr-ticket`);

    try {
      // Get all users
      const snapshot = await get(userRef);
  
      // Check if snapshot exists
      if (snapshot.exists()) {
        let tcExists = false;
  
        // Loop through all users and check if any user has the matching TC value
        snapshot.forEach((childSnapshot) => {
          
          const userData = childSnapshot.val();
          console.log(userData.TC);
          if (String(userData.TC) === tcValue) {
            tcExists = true;
          }
        });
  
        // Display result
        if (tcExists) {
          alert("Onay");
        } else {
          alert("Kayıtlı değil");
        }
      } else {
        alert("No users found in the database.");
      }
    } catch (error) {
      console.error("Error querying the database: ", error);
      alert("There was an error checking the QR code.");
    }
  };

  return (
    <div>
      <h1>QR Code Scanner</h1>
      <div id="qr-reader" style={{ width: "300px" }}></div>
      <div id="qr-reader-results"></div>
    </div>
  );
};

export default QRScanner;
