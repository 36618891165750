import React from "react";
import { useAuth } from "./auth";
import QRScanner from "./scanner";
import LoginForm from "./form"; 

const App = () => {
  // Get the current authenticated user
  const currentUser = useAuth();

  return (
    <div>
      {currentUser ? (
        <div>
          <h2>Welcome, {currentUser.email}</h2>
          {/* Show the QR Scanner if the user is logged in */}
          <QRScanner />
        </div>
      ) : (
        <div>
          <h2>Please Log In</h2>
          {/* Show the login form if the user is not logged in */}
          <LoginForm />
        </div>
      )}
    </div>
  );
};

export default App;
